.item {
    width: 280px;
}
.item img{
    width: 280px;
}
.item p {
    margin: 6px 0px;
}
.item-prices {
    display: flex;
    gap: 20px;
}
.item-price-new {
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}
.item-price-old {
    color:#8c8c8c ;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}
.item:hover {
    transform: scale(1.05);
    transition: 0.6s;
}
@media(max-width:1290px){
    .item {
        width: 220px;
        font-size: 14px;
    }
    .item img{
        width: 220px;
    }
    .item-price-old{
        font-size: 14px;
    }
}
@media (max-width:1024px){
    .item{
        width: 170px;
        font-size: 13px;
    }
    .item img{
        width: 170px;
    }
    .item-price-old{
        font-size: 13px;
    }
    .item-price-new{
        font-size: 13px;
    }
}
@media (max-width:800px){
    .item{
        width: 120px;
        font-size: 12px;
    }
    .item img{
        width: 120px;
    }
    .item-price-old{
        font-size: 12px;
    }
}
@media (max-width:500px){
    .item{
        width: 160px;
    }
    .item img{
        width: 160px;
    }
}