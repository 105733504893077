.popular{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin:150px;
    text-align: center;
}
.popular h1{
    color: #171717;
    font-weight: 600;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
.popular hr{
    width: 200px;
    height: 6px;
    border-radius: 10px;
    background: #252525;
}
.popular-item{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 50px;
    gap: 30px;
}
@media(max-width:1290px){
    .popular{
    }
    .popular h1{
        font-size: 40px;
    }
    .popular hr{
        width: 160px;
        height: 4px;
    }
    .popular-item{
        gap: 20px;
        margin-top: 30px;
    }
}
@media(max-width:1024px){
    .popular{
    }
    .popular h1{
        font-size: 30px;
    }
    .popular hr{
        width: 120px;
        height: 3px;
    }
    .popular-item{
        gap: 15px;
        margin-top: 20px;
    }

}
@media(max-width:800px){
    .popular{
        gap: 6px;
    }
    .popular h1{
        font-size: 20px;
    }
    .popular hr{
        width: 100px;
    }
    .popular-item{
        gap: 5px;
    }
}
@media (max-width:500px){
    .popular h1{
        font-size: 15px;
    }
    .popular-item{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        text-align: center;
    }
}