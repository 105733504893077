.hero {
    height: 100vh;
    display: flex;
    /* height: 100vh;
    background: linear-gradient(180deg, #fde1ff, #e1ffea22 60%);
    display: flex;kyle-mills-Bh7yCs7AcNs-unsplash */
    /* background-image: url(../Assets/wp5016668-best-wedding-4k-wallpapers.jpg); */
    background:
        /* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.4), 
          rgba(0, 0, 0, 0.4)
        ),
        /* bottom, image */
        url(../Assets/wp5016668-best-wedding-4k-wallpapers.jpg);
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover; 
}
.hero-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding-left: 180px;
    line-height: 1.1;
}
.hero-left h2 {
    color: #ffffff ;
    font-size: 50px;
    font-weight: 600;
    font-family: Gwendolyn;
}
.hero-left p {
    color: #ffffff;
    font-size: 50px;
    font-weight: 600;
    font-family: Gwendolyn;

}
.hero-hand-icon {
    display: flex;
    align-items: center;
    gap: 20px;    
}
.hero-hand-icon img {
    width: 105px;
}
.hero-latest-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 200px;
    height: 50px;
    border-radius: 75px;
    background: #ff4141;
    color: white;
    font-size: 30px;
    font-weight: 500;
    font-family: Gwendolyn;
} 
.hero-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media(max-width:1290px){
    .hero-left{
        padding-left: 100px;
    }
    .hero-left h2{
        font-size: 49px;
    }
    .hero-left p{
        font-size: 60px;
    }
    .hero-hand-icon img{
        width: 80px;
    }
    .hero-latest-btn{
        gap: 10px;
        width: 220px;
        height: 60;
        font-size: 30px;
    }
    /* .hero-right img{
        width: 500px;
    } */
}
@media(max-width:1024px){
    .hero-left{
        padding-left:50px;
    }
    .hero-left h2{
        font-size: 50px;
    }
    .hero-left p{
        font-size: 50px;
    }
    .hero-hand-icon img{
        width: 65px;
    }
    .hero-latest-btn{
        width: 220px;
        height: 50;
        font-size: 30px;
    }
    /* .hero-right img{
        width: 400px;
    } */
}
@media(max-width:800px){
    .hero-left{
        padding-left:30px;
    }
    .hero-left h2{
        font-size: 40px;
    }
    .hero-left p{
        font-size: 40px;
    }
    .hero-hand-icon img{
        width: 50px;
    }
    .hero-latest-btn{
        width: 175px;
        height: 40;
        font-size: 30px;
    }
    .hero-right img{
        width: 300px;
    }
}
@media(max-width:500px){
    .hero-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        padding-left: 40px;
        line-height: 1.1;
    }
    .hero{
        height: 100vh;
        display: flex;
        /* height: 100vh;
        background: linear-gradient(180deg, #fde1ff, #e1ffea22 60%);
        display: flex;kyle-mills-Bh7yCs7AcNs-unsplash */
        /* background-image: url(../Assets/kyle-mills-Bh7yCs7AcNs-unsplash.jpg); */
        background:
        /* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.4), 
          rgba(0, 0, 0, 0.4)
        ),
        /* bottom, image */
        url(../Assets/kyle-mills-Bh7yCs7AcNs-unsplash.jpg);
        background-position: center;
        background-repeat: no-repeat; 
        background-size: cover; 
        /* padding-left: 20px;
        flex-direction: column;
        height: 800px;
        height: 100vh;
        background: linear-gradient(180deg, #eeb3ad, #ced6d022 60%);
        display: flex; */

    }
    .hero-right{
        display: none;
    }
    .hero-left h2{

        font-size: 35px;
        color: #f5eeee;
    }
    .hero-left p{
        display: none;
        font-size: 30px;
        color: black;
    }
    .hero-hand-icon img{
        width: 55px;
    }
    .hero-latest-btn{
        background-color: #cf7979 ;
        width: 150px;
        height: 45px;
        font-size: 25px;
        font-weight: 700;
    }
    .hero-latest-btn img{
        height: 15px;
    } 
}
