.relatedproducts {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: 90vh;
}
.relatedproducts h1 {
    color: #171717;
    font-size: 50px;
    font-weight: 600;
}
.relatedproducts hr {
    width: 200px;
    height: 6px;
    border-radius: 10px;
    background: #252525;
}
.relatedproducts-item {
    /* margin-top: 50px;
    display: flex;
    gap: 30px; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 50px;
    gap: 30px;
}
@media(max-width:1290px){
    .relatedproducts{
        height: 70vh;
    }
    .relatedproducts h1{
        font-size: 40px;
    }
    .relatedproducts hr{
        width: 160px;
        height: 4px;
    }
    .relatedproducts-item{
        gap: 20px;
        margin-top: 30px;
    }
}
@media(max-width:1024px){
    .relatedproducts{
        height: 60vh;
    }
    .relatedproducts h1{
        font-size: 30px;
    }
    .relatedproducts hr{
        width: 120px;
        height: 3px;
    }
    .relatedproducts-item{
        gap: 15px;
        margin-top: 20px;
    }

}
@media(max-width:800px){
    .relatedproducts{
        height: 50vh;
        gap: 6px;
    }
    .relatedproducts h1{
        font-size: 20px;
    }
    .relatedproducts hr{
        width: 100px;
    }
    .relatedproducts-item{
        gap: 5px;
    }
}
@media (max-width:500px){
    .relatedproducts{
        height: 90vh;
    }
    .relatedproducts-item{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}